import { AccountPageHeader } from '../account/AccountPageHeader';
import { AccountOrderSideBar } from '../account/AccountOrderSideBar';
import { NoOrders } from '../account/NoOrders';
import { Box } from '../order/box/Box';
import { OrderBuyAgain } from '../order/OrderBuyAgain';
import { AccountLayout } from '../AccountLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePageTracking from 'hooks/tracking/usePageTracking';
import PageTagFactory from 'modules/tracking/tags/page/PageTagFactory';
import useSavings from 'hooks/useSavings';
import { useFetchOrdersQuery } from 'redux/query/order.query';
import { useResponsive } from 'hooks/useResponsive';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { useAppSelector } from 'redux/appStore';
import { selectTaxons } from 'redux/common/common.slice';
import { useRequiredAccountSession } from 'hooks/useRequiredSession';

export function OrdersIndex() {
  const { token } = useRequiredAccountSession();
  const { t } = useTranslation();

  const { data: orders, isLoading, isError, isSuccess } = useFetchOrdersQuery();
  const latestOrder = orders?.length ? orders[0] : null;
  const taxons = useAppSelector(selectTaxons);

  const { trackPage } = usePageTracking();

  useEffect(() => {
    trackPage(PageTagFactory.accountPage());
  }, [trackPage]);

  const { savings } = useSavings();
  const { isLg } = useResponsive();

  return (
    <AccountLayout
      taxons={taxons}
      headerComponent={
        <AccountPageHeader
          title={t('app.ui.my_orders')}
          subtitle={t('app.ui.my_orders_subheader')}
        />
      }
      rightSideBarComponent={<AccountOrderSideBar savings={savings} order={latestOrder || null} />}
    >
      {isError && <h3>{t('app.ui.something_went_wrong')}</h3>}
      {isLoading && <ActivityIndicator container />}
      {isSuccess && !orders?.length && <NoOrders />}
      {isSuccess &&
        orders?.length &&
        orders.map((order, index) => (
          <div className="mb-4" key={`order-${index}`}>
            <Box>
              <Box.Header order={order} />
              <Box.Items order={order} isLg={isLg} />
              <Box.Footer>
                <div className="md:flex md:items-center md:gap-4">
                  <Box.ViewOrderDetailsButton
                    orderDetailsPath={`/account/orders/${order.tokenValue}`}
                  />
                  <div className="flex center w-100 mt-3 md:mt-0">
                    <OrderBuyAgain order={order} />
                  </div>
                </div>
              </Box.Footer>
            </Box>
          </div>
        ))}
    </AccountLayout>
  );
}
