import { BigFilledButton } from '../BigFilledButton';
import { useTranslation } from 'react-i18next';

export function NoOrders() {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <div className="font-semibold py-12">{t('app.ui.orders.no_orders')}</div>
      <BigFilledButton text={t('app.ui.orders.find_your_first_deal')} href={'/'} />
    </div>
  );
}
