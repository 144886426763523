import { useSession as useNextSession } from 'next-auth/react';
import { Session } from 'types/types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useCallbackUrl from './useCallbackUrl';

const useRequiredSession = ({
  from,
  callbackUrl,
  enableGuestBrowsing,
}: {
  from: string;
  callbackUrl: string;
  enableGuestBrowsing?: boolean;
}): Session => {
  const router = useRouter();
  const { setCallbackUrl } = useCallbackUrl();

  useEffect(() => {
    setCallbackUrl(callbackUrl);
  });

  const { data, status } = useNextSession({
    required: !Boolean(enableGuestBrowsing),
    onUnauthenticated: () => {
      router.push(`/login?from=${from}`);
    },
  });

  const session = data as unknown as Session['session'] | null;
  const token = session?.token || null;

  return { session, status, token };
};

export const useRequiredCheckoutSession = (enableGuestBrowsing = false): Session => {
  const router = useRouter();
  return useRequiredSession({
    from: 'checkout',
    callbackUrl: router.asPath,
    enableGuestBrowsing,
  });
};

// @todo make this session to be not null in return
export const useRequiredAccountSession = (enableGuestBrowsing = false): Session => {
  const router = useRouter();
  return useRequiredSession({
    from: 'account',
    callbackUrl: router.asPath,
    enableGuestBrowsing,
  });
};
