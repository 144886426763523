import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Taxon } from 'types/types';
import { Layout } from './Layout';
import { ActivityIndicator } from './ActivityIndicator';

interface Props {
  extraHeader?: ReactNode;
  taxons: Taxon[];
}

export function AccessDenied({ taxons, extraHeader }: Props) {
  const { t } = useTranslation();
  return (
    <Layout taxons={taxons}>
      <div className="container flex flex-col w-full mb-8">
        {extraHeader}
        <h1 className="mb-4 font-bold">{t('app.ui.access_denied_loading_title')}</h1>

        <ActivityIndicator />
      </div>
    </Layout>
  );
}

export default AccessDenied;
