import { AccessDenied } from 'components/AccessDenied';
import { useRequiredAccountSession } from 'hooks/useRequiredSession';
import { OrdersIndex } from 'components/orders/IndexPage';
import { useStore } from 'store';
import { accountGetServerSideProps } from 'components/account/AccountGetServerSideProps.lib';
import { useAppSelector } from 'redux/appStore';
import { selectTaxons } from 'redux/common/common.slice';

export default function OrdersPage() {
  const { session } = useRequiredAccountSession();
  const [customer] = useStore('customer');
  const taxons = useAppSelector(selectTaxons);

  if (!session || !customer) return <AccessDenied taxons={taxons} />;
  return <OrdersIndex />;
}

export const getServerSideProps = accountGetServerSideProps;
