import { useSession } from 'hooks/useSession';
import { getBuyAgainItems } from 'lib/cart/cart';
import { handleAddToCart } from 'lib/cart/handleAddToCart';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { TrackingProductList } from 'types/tracking';
import { Order } from 'types/types';
import { ActivityIndicator } from '../ActivityIndicator';

export function OrderBuyAgain({ order }: { order: Order }) {
  const { t } = useTranslation();
  const [cart, setCart] = useStore('cart');
  const { session } = useSession();
  const [buyingAgain, setBuyingAgain] = useState<boolean>(false);
  const router = useRouter();

  const sellableItems = order.items.filter((item) => item.productSellable);

  async function handleBuyAgain() {
    setBuyingAgain(true);
    for (const item of getBuyAgainItems(order)) {
      await handleAddToCart({
        cart,
        t,
        variant: item.variant,
        productList: TrackingProductList.accountOrder,
        product: item.product,
        quantity: item.quantity,
        authToken: session?.token || null,
        setCart: setCart,
      });
    }
    setBuyingAgain(false);
    await router.push('/cart');
  }

  if (sellableItems.length === 0) return null;

  if (buyingAgain) return <ActivityIndicator />;

  return (
    <a className="text-purple cursor-pointer underline" onClick={() => handleBuyAgain()}>
      {t('app.ui.buy_again')}
    </a>
  );
}
